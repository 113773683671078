.AdminLoginContainer {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: linear-gradient(
      to bottom,
      rgba(245, 246, 252, 0.52),
      rgba(243, 240, 242, 0.73)
    ),
    url("../../Assets/homebg.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 10px;
  flex-direction: column;
}

.exitBtn {
  width: 140px;
  height: 40px;
  margin-top: 30px;
  font-size: 22px;
  border-radius: 4px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: black;
  text-decoration: none !important;
  border: 2px solid black;
}

.plusIcon {
  margin-right: 7px;
  margin-top: 2px;
}

.AdminLoginWrapper {
  max-width: 480px;
  height: auto;
  background: var(--color4);
  padding: 50px 60px;
  border-radius: 5px;
}

.AdminLoginWrapper h1 {
  color: var(--color14);
}

.AdminLoginWrapper {
  position: relative;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3),
    0 0 40px rgba(0, 0, 0, 0.1) inset;
  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3),
    0 0 40px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
}

.AdminLoginWrapper:before,
.AdminLoginWrapper:after {
  content: "";
  position: absolute;
  z-index: -1;
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
  -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
  top: 10px;
  bottom: 10px;
  left: 0;
  right: 0;
  -moz-border-radius: 100px / 10px;
  border-radius: 100px / 10px;
}

.AdminLoginWrapper:after {
  right: 10px;
  left: auto;
  -webkit-transform: skew(8deg) rotate(3deg);
  -moz-transform: skew(8deg) rotate(3deg);
  -ms-transform: skew(8deg) rotate(3deg);
  -o-transform: skew(8deg) rotate(3deg);
  transform: skew(8deg) rotate(3deg);
}

.AdminLoginWrapper input {
  width: 320px;
  height: 44px;
  font-size: 20px;
  font: 700;
  margin: 15px auto;
  border: 1px solid var(--color3);
  border-radius: 2px;
  padding-left: 10px;
}

.AdminLoginWrapper input[type="submit"] {
  cursor: pointer;
  width: 332px;
}

.AdminLoginWrapper input:hover,
.AdminLoginWrapper input:focus {
  background-color: #ccc;
  outline: none;
}

/* media query for responsive */
@media screen and (max-width: 520px) {
  .AdminLoginWrapper {
    max-width: 420px;
    padding: 40px 40px;
  }
}

@media screen and (max-width: 440px) {
  .AdminLoginWrapper {
    max-width: 378px;
    padding: 30px 20px;
  }
}

@media screen and (max-width: 400px) {
  .AdminLoginWrapper {
    max-width: 320px;
    padding: 20px 20px;
  }

  .AdminLoginWrapper input {
    width: 100%;
  }

  .AdminLoginWrapper input[type="submit"] {
    width: 100%;
  }
}

@media screen and (max-width: 324px) {
  .AdminLoginWrapper {
    max-width: 300px;
    padding: 10px 10px;
  }
}
