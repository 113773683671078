#modal {
  background-color: rgba(0, 0, 0, 0.5);
}

.modalContent {
  margin: auto;
  background: #fff;
  width: 400px;
  height: auto;
  margin-top: 10%;
  padding: 20px 10px;
  border-radius: 20px;
}

.modalContent h4 {
  text-align: center;
  font-size: 20px;
  margin-top: 30px;
}

.addRoomForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 800%;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  margin-top: -20px;
}

.addRoomForm label {
  font-size: 12px !important;
  font-weight: 500;
  margin-right: auto;
  margin-left: 15px;
}

.addRoomForm input[type="text"],
.addRoomForm input[type="number"] {
  width: 100%;
  height: 40px;
  border-radius: 18px;
  border: 1px solid lightgray;
  margin-bottom: 20px;
  outline: none;
  padding-left: 15px;
}

.modalClose {
  font-size: 30px;
  font-weight: 300;
  float: right;
  color: grey;
  margin: -7px 7px 0 0;
  cursor: pointer;
}

.modalContent {
  position: relative;
  -webkit-box-shadow: 0 1px 4px rgba(129, 127, 127, 0.3),
    0 0 40px rgba(0, 0, 0, 0.1) inset;
  -moz-box-shadow: 0 1px 4px rgba(129, 127, 127, 0.3),
    0 0 40px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 4px rgba(129, 127, 127, 0.3);
}
.modalContent:before,
.modalContent:after {
  content: "";
  position: absolute;
  z-index: -1;
  -webkit-box-shadow: 0 0 20px rgba(139, 139, 139, 0.8);
  -moz-box-shadow: 0 0 20px rgba(139, 139, 139, 0.8);
  box-shadow: 0 0 20px rgba(139, 139, 139, 0.8);
  top: 0;
  bottom: 0;
  left: 10px;
  right: 10px;
  -moz-border-radius: 100px / 10px;
  border-radius: 100px / 10px;
}
.modalContent:after {
  right: 10px;
  left: auto;
  -webkit-transform: skew(8deg) rotate(3deg);
  -moz-transform: skew(8deg) rotate(3deg);
  -ms-transform: skew(8deg) rotate(3deg);
  -o-transform: skew(8deg) rotate(3deg);
  transform: skew(8deg) rotate(3deg);
}

.modHeader2 {
  display: none;
  color: var(--color1);
}

.modal {
  width: 100% !important;
  min-width: 400px !important;
}

.createRoom {
  max-width: 170px;
  min-width: 170px;
  margin: 0px 19px 15px 0px !important;
  height: 130px;
  background: #fff;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--color1);
  font-weight: 500;
  border-bottom-left-radius: 16px !important;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px !important;
  border-bottom-right-radius: none !important;
  box-shadow: 0 2.8px 2.2px rgba(141, 140, 140, 0.034),
    0 6.7px 5.3px rgba(134, 134, 134, 0.048),
    0 12.5px 10px rgba(138, 137, 137, 0.06),
    0 22.3px 17.9px rgba(124, 124, 124, 0.072),
    0 41.8px 33.4px rgba(116, 115, 115, 0.086),
    0 100px 80px rgba(136, 134, 134, 0.12);
}

.ccWrapper {
  width: 420px;
  overflow: scroll;
}

.ccContainer {
  width: 1200px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 10px 20px 10px;
}

.ccContainer input[type="radio"] {
  display: none;
  width: 48px;
  height: 48px;
}

.ccContainer div {
  width: 48px;
  height: 48px;
  cursor: pointer;
}

.ccContainer div:hover {
  transform: scale(1.15);
  transition: 0.2s;
}

.ccContainer div::active,
.ccContainer div::focus {
  transform: scale(1.15);
}

@media (max-width: 767px) {
  .react-responsive-modal-modal {
    width: 50px !important;
  }
}
