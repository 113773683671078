._wrapper {
  min-height: 60px;
  padding-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: right;

  * {
    margin: 0;
    padding: 0;

    @media screen and (max-width: 768px) {
      font-size: 0.8rem;
    }
  }

  ._btn {
    margin-bottom: 1rem;
    background-color: #000;
    color: #fff;
    padding: 5px 10px;
    border-radius: 0;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &:hover {
      background-color: #000;
      color: #fff;
    }
  }
}
