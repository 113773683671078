.topBtn {
  background: none;
  border: 1px solid transparent !important;
  color: var(--color1) !important;
  font-weight: 300;
  width: 40px;
  height: 36px;
  background: none !important;
  box-shadow: none !important;
  padding: 0;
}

.topBtn svg {
  -webkit-text-stroke: 1px white;
}

.roomCardTop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  top: 0 !important;
  width: 100%;
  border: none;
  background: transparent;
  padding: 0;
  height: 30px;
}

.roomNo {
  font-weight: 500;
  top: 0;
  margin: 0 auto;
  padding: 0;
}

/* .topBtn:hover {
    color: #fff !important;
    background: var(--color25) !important;
    border: 1px solid var(--color1) !important;
  }

  .topBtn:active,
  .topBtn:focus {
    background-color: var(--color1) !important;
    color: #fff !important;
  } */

.dashCard {
  max-width: 200px;
  margin: 0px 19px 15px 0px !important;
  height: 100%;
  background: #fff;
  border: 1px solid rgb(248, 250, 250);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--color1);
  font-weight: 500;
  border-bottom-left-radius: 16px !important;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px !important;
  border-bottom-right-radius: 0px !important;
  box-shadow: 0 2.8px 2.2px rgba(173, 172, 172, 0.034),
    0 6.7px 5.3px rgba(173, 172, 172, 0.034),
    0 12.5px 10px rgba(173, 172, 172, 0.034),
    0 22.3px 17.9px rgba(173, 172, 172, 0.034),
    0 41.8px 33.4px rgba(173, 172, 172, 0.034),
    0 100px 80px rgba(173, 172, 172, 0.034);
}

.dashCard:hover {
  border-color: var(--color22);
}

/* button styles !!!YOU NEED THEM !!!ALSO YOU NEED TO ADD FONTWESOME */
.effect {
  text-align: center;
  position: relative;
  text-decoration: none;
  background: var(--color1);
  color: var(--color2);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  padding: 0px;
  width: 42px;
  height: 42px;
  border-radius: 20px;
  overflow: auto;
  border: none;
  outline: none;
}

/* effect4 styles */

.roomNo {
  border-radius: 20px;
  border: none;
  background: var(--color1);
  color: var(--color2);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 42px;
  height: 42px;
  outline: none;
  padding: 0;
  margin: 0;
  overflow: auto;
}

.effect.effect4 {
  transition: all 0.2s linear 0s;
}

.effect.effect4:before {
  content: "\f055";
  font-family: FontAwesome;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0px;
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 30px;
  transform: scale(0, 1);
  transition: all 0.2s linear 0s;
}

.effect.effect4:hover {
  text-indent: -9999px;
  background: var(--color1);
  color: #fff;
}

.effect.effect4:hover:before {
  transform: scale(1, 1);
  text-indent: 0;
}

.effect.effect4::active {
  background: var(--color1);
  color: #fff;
}

.effect.effect4::focus {
  background: var(--color1);
  color: #fff;
}

.topLeft {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 45%;
  height: 30px;
  align-items: center;
}

.roomCardTop {
  display: flex;
  flex-direction: row;
  top: 0;
  left: 0;
  height: 30px;
}

.topLeft span {
  height: 30px;
  width: 60%;
  background-color: var(--color25);
  color: var(--color4);
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-right-radius: 15px;
  border-top-left-radius: 15px;
  font-size: 14px;
}

.timer {
  color: var(--color22);
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 30%;
  text-align: right;
}

.roomCardMid {
  height: auto !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.alert {
  height: 64px;
  width: 64px;
  background: #fff;
  border-radius: 50%;
  border: 2px solid #ccc;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  color: #ccc;
}

.wrapper {
  overflow: auto;
  position: relative;
  width: 200px;
  text-align: center;
  /* real length of dropdown */
  border: none;
  padding: 9px 15px;
  background: transparent;
  margin: 0 auto !important;
  color: var(--color4);
}



@media screen and (max-width: 575px) {
  .dashCard {
    max-width: 100% !important;
    display: flex !important;
  }
}