#blinker {
    font-size: 22px;
    width: 22px;
}

#emergencyBtn {
    border: 2px solid var(--color1);
    border-radius: 50%;
    background-color: transparent;
    color: var(--color1);
    font-size: 11px;
    width: 22px;
    height: 22px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 6px;
    margin-right: 2px;
}

#emergencyBtn:hover {
    color: var(--color13);
    border-color: var(--color13);
}

span i {
    -moz-animation-duration: 500ms;
    -moz-animation-name: blink;
    -moz-animation-iteration-count: infinite;
    -moz-animation-direction: alternate;
    -webkit-animation-duration: 500ms;
    -webkit-animation-name: blink;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-direction: alternate;
    animation-duration: 500ms;
    animation-name: blink;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    color: red;
}

@-moz-keyframes blink {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@-webkit-keyframes blink {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@keyframes blink {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}


#alarming {
    -webkit-animation: glowing 1500ms infinite;
    -moz-animation: glowing 1500ms infinite;
    -o-animation: glowing 1500ms infinite;
    animation: glowing 1500ms infinite;
    border: none;
}

@-webkit-keyframes glowing {
    0% {
        background-color: #B20000;
        -webkit-box-shadow: 0 0 3px #B20000;
    }

    50% {
        background-color: #FF0000;
        -webkit-box-shadow: 0 0 40px #FF0000;
    }

    100% {
        background-color: #B20000;
        -webkit-box-shadow: 0 0 3px #B20000;
    }
}

@-moz-keyframes glowing {
    0% {
        background-color: #B20000;
        -moz-box-shadow: 0 0 3px #B20000;
    }

    50% {
        background-color: #FF0000;
        -moz-box-shadow: 0 0 40px #FF0000;
    }

    100% {
        background-color: #B20000;
        -moz-box-shadow: 0 0 3px #B20000;
    }
}

@-o-keyframes glowing {
    0% {
        background-color: #B20000;
        box-shadow: 0 0 3px #B20000;
    }

    50% {
        background-color: #FF0000;
        box-shadow: 0 0 40px #FF0000;
    }

    100% {
        background-color: #B20000;
        box-shadow: 0 0 3px #B20000;
    }
}

@keyframes glowing {
    0% {
        background-color: #B20000;
        box-shadow: 0 0 3px #B20000;
    }

    50% {
        background-color: #FF0000;
        box-shadow: 0 0 40px #FF0000;
    }

    100% {
        background-color: #B20000;
        box-shadow: 0 0 3px #B20000;
    }
}