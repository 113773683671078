.reportsContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: .5rem 1rem;
    flex-wrap: wrap;
}

.reportsBodyFilter {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.reportsBodyFilter input {
    width: 95%;
    height: 42px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: .5rem 1rem;
    font-size: 1rem;
    outline: none;
    background-color: #fff;
    margin-right: 1rem;
}





.reportsBodyFilter button {
    width: 100%;
    height: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: .5rem 1rem;
    font-size: 1rem;
    outline: none;
    background-color: #fff;
}

.reportsBodyFilter button:hover {
    background-color: #ccc;
}

.reportsBodyContent {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    background-color: rgb(215, 229, 242);
    padding: 1rem;
    border-radius: 5px;
}