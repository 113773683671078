#dashboard {
  width: 100%;
  padding: 0;
  margin: 0 !important;
  margin-left: 15px;
  background-color: #fff !important;
}

.dropBoxParent {
  width: 100vw;
  overflow: scroll;
}

#DropBox {
  height: auto;
  width: auto;
  min-width: 300vw;
  display: flex;
  align-items: center;
  flex-direction: row !important;
  padding-left: 5px;
  padding-top: 12px;
  padding-right: 10px;
  margin: 0px;
}

.drArea {
  padding: 20px 0px 0px 25px;
  height: auto;
  width: 100%;
}

.drAreaTitle {
  border-bottom: 1px solid #c0e7e5;
  height: 60%;
}

.drAreaBottom {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: top;
  padding-top: 10px;
}

.drAreaWrapper {
  border-right: 1px solid #c0e7e5;
  height: 175px;
  padding-right: 20px;
}

.drDeck {
  background: #f2f2fc;
  margin-bottom: 15px !important;
}

.parent {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 5px;
  grid-row-gap: 5px;
}

.drAreaTop {
  height: 20%;
  text-align: right;
}

@media screen and (max-width: 575px) {
  #dashboard {
    margin-top: 15vw !important;
    width: 95% !important;
    overflow: auto;
  }

  .drAreaWrapper {
    border-right: none;
    padding-right: 0px;
    height: 150px;
  }

  .drArea {
    width: 100% !important;
    padding-right: 0px;
  }

  .dropBoxParent {
    overflow: scroll !important;
    width: inherit !important;
  }

  #DropBox {
    height: auto;
    min-width: 100% !important;
    display: flex;
    flex-direction: row;
    padding-right: 0px;
  }

  .drDeck {
    width: 100%;
  }
}