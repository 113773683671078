@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

.modalContainer {
  display: grid;
  grid-template-columns: auto auto auto;
}

.modalContainer > .leftSideItems .rightSideItems {
  width: 49%;
  margin: 10px;
  text-align: center;
  font-size: 30px;
  display: inline;
}
.middleBorder {
  height: 500px;
  background-color: #d9d9d9;
  width: 1px;
  margin: auto 30px;
}

.items {
  width: 294px;
  height: 90px;
  display: flex;
  align-items: center;
  border-radius: 50px;
  padding-left: 10px;
  margin: 20px 20px 20px;
  cursor: pointer;
}

.items:hover {
  background-color: #f6faf9;
}

.active {
  background-color: rgba(106, 199, 190, 0.4);
}

.active:hover {
  background-color: rgba(106, 199, 190, 0.4);
}

.items div {
  display: flex;
  align-items: center;
}

.items div > h1 {
  width: 66px;
  height: 66px;
  border-radius: 50%;
  text-align: center;
  line-height: 66px;
  margin: 0;
}

.items div > p {
  padding-left: 20px;
  font-size: 16px;
  line-height: 32px;
  margin-bottom: 18px;
}

.items div h1,
.items div p {
  display: inline-block;
}

@media only screen and (max-width: 768px) {
  .middleBorder {
    display: none;
  }
  .modalContainer {
    display: grid;
    grid-template-columns: auto;
  }
}
