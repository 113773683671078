#RBtn {
    height: 23px;
    width: 23px;
    left: 944px;
    top: 1078px;
    border-radius: 50%;

    padding: 11px, 41px, 11px, 41px;
    background-color: var(--color3);
    border: none;
    color: var(--color1);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    align-items: center;

    border: 2px solid var(--color1);

}

#RBtn:hover {
    background-color: var(--color25);
    border: 2px solid var(--color1);
    color: var(--color3);
    font-weight: 500;
}

#RBtn:focus {
    outline: none;
    border: 2px solid var(--color4);
    color: var(--color4);
    background-color: var(--color25);

}