.sequenceContainer {
  padding: 15px !important;
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px 10px;
  grid-template-areas: ".";
  width: 100%;
}

.sequenceTop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-height: 200px !important;
}

.wrapper {
  overflow: auto;
  position: relative;
  width: 20rem;
  /* real length of dropdown */
  border: 1px solid lightgray;
  border-radius: 20px;
  padding: 9px 20px;
  height: 100%;
  width: calc((100vw - 7.2rem) * (4 / 3));
  max-width: 100vw;
}

.wrapper:after {
  content: "▾";
  font-size: 1.1rem;
  pointer-events: none;
  /* ▼ click triggers dropdown */
  position: absolute;
  right: 1rem;
  top: 0.5rem;
  z-index: 1;
}

select {
  /* remove default caret */
  -webkit-appearance: none;
  /* webkit browsers */
  -moz-appearance: none;
  /* firefox */
  appearance: none;
  /* modern browsers */
  width: 100%;
  border: none;
  background: transparent;
  outline: none;
  font-weight: 400;
}

label {
  font-weight: 600;
}

select::-ms-expand {
  display: none;
  /* remove default caret for ie */
}

.dropBoxParent {
  width: 100%;
  overflow: scroll;
  padding-left: 0 !important;
  padding-right: 0px;
}

#DropBox {
  height: 170px;
  width: 100%;
  margin: 20px auto;
  background: #fff;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-left: 15px;
  padding-top: 10px;
  padding-right: 15px;
  overflow-y: hidden;
}

#DropBox {
  background-image: repeating-linear-gradient(-2deg,
      var(--color1),
      var(--color1) 5px,
      transparent 5px,
      transparent 10px,
      var(--color1) 10px),
    repeating-linear-gradient(88deg,
      var(--color1),
      var(--color1) 5px,
      transparent 5px,
      transparent 10px,
      var(--color1) 10px),
    repeating-linear-gradient(178deg,
      var(--color1),
      var(--color1) 5px,
      transparent 5px,
      transparent 10px,
      var(--color1) 10px),
    repeating-linear-gradient(268deg,
      var(--color1),
      var(--color1) 5px,
      transparent 5px,
      transparent 10px,
      var(--color1) 10px);
  background-size: 1px 100%, 100% 1px, 1px 100%, 100% 1px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
}

.RoomsContainer {
  padding: 0px 15px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 2px 2px;
  grid-template-areas: ". . . . . .";
}

.createRoom {
  height: 100%;
  max-height: 130px;
  width: calc((100vw - 4rem) * (3 / 4));
  max-width: calc(800px * 3 / 4);
  margin: 0px 2% 2% 0px !important;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--color1);
  font-weight: 500;
  border-bottom-left-radius: 16px !important;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px !important;
  border-bottom-right-radius: none !important;
  box-shadow: 0 2.8px 2.2px rgba(180, 179, 179, 0.12),
    0 6.7px 5.3px rgba(180, 179, 179, 0.12),
    0 12.5px 10px rgba(180, 179, 179, 0.12),
    0 22.3px 17.9px rgba(180, 179, 179, 0.12),
    0 41.8px 33.4px rgba(180, 179, 179, 0.12),
    0 100px 80px rgba(180, 179, 179, 0.12);
  background-color: transparent;
}

.alert {
  padding: 20px;
  background-color: #f44336;
  color: white;
}

.closeBtn {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}

.closeBtn:hover {
  color: black;
}

@media (max-width: 768px) {
  .sequenceContainer {
    transform: translate(0, 5%);
    padding: 5px !important;
    margin-top: 5vw !important;
  }

  .wrapper {
    margin-bottom: -20px;
  }

  .RoomsContainer {
    padding: 0px !important;
  }
}

@media (max-width: 480px) {
  .sequenceContainer {
    transform: translate(0, 6%);
    padding: 5px !important;
  }

  .sequenceTop {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
  }

  .wrapper {
    margin-bottom: -20px;
  }

  .RoomsContainer {
    padding: 0px !important;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .createRoom {
    width: 30%;
    margin: 0px 3% 3% 0px !important;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .createRoom {
    min-width: 120px;
    max-width: 120px;
  }

  .wrapper {
    max-width: 400px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .createRoom {
    min-width: 140px;
    max-width: 140px;
  }

  .wrapper {
    max-width: 480px;
  }
}

@media (min-width: 1025px) {
  .createRoom {
    min-width: 170px;
    max-width: 170px;
  }

  .wrapper {
    max-width: 320px;
  }
}