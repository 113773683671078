#DeleteBtn {
  height: 40px;
  width: 126px;
  left: 486px;
  top: 1133px;
  border-radius: 20px;
  padding: 11px, 41px, 11px, 41px;

  border: none;
  background-color: var(--color3);
  color: var(--color1);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

#DeleteBtn:hover {
  border: 2px solid var(--color1);
}

#DeleteBtn:focus {
  color: var(--color4);
  border: 2px solid var(--color4);

  outline: none;
}
